import { Directive, ElementRef, Renderer2, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SwipeService } from 'src/app/services/swipe.service';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements OnChanges {

  // Dynamically control the size of the button
  @Input('appDraggable') isLarge = true; 

  private offsetX = 0;
  private offsetY = 0;
  private dragging = false;
  private movedDistance = 0;
  private threshold = 2; // Minimum offset to define drag
  private justDragged = false;
  private startX = 0;
  private startY = 0;
  private isDraggingHorizontally = false;

  constructor( private el: ElementRef, private renderer: Renderer2, private swipeService: SwipeService) {

    this.setupTouchListeners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['isLarge'] && !changes['isLarge'].firstChange) {

      this.handleSizeChange();
    }
  }

  ngOnInit(){
    this.loadPosition()
  }

  private setupTouchListeners() {
    this.el.nativeElement.addEventListener('touchstart', (event: TouchEvent) => {
      this.movedDistance = 0;
      this.justDragged = false;
      this.isDraggingHorizontally = false;
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;

      event.preventDefault();
      this.startDrag(this.startX, this.startY);

      // Notifying SwipeService about the start of the drag
      this.swipeService.setDraggingState(true);
    }, { passive: false });

    this.el.nativeElement.addEventListener('touchmove', (event: TouchEvent) => {
      const currentX = event.touches[0].clientX;
      const currentY = event.touches[0].clientY;
      const deltaX = Math.abs(currentX - this.startX);
      const deltaY = Math.abs(currentY - this.startY);

      // Check if this is a horizontal movement
      if (deltaX > deltaY && deltaX > this.threshold) {
        this.isDraggingHorizontally = true;
      }

      if (this.dragging) {
        // Block page scrolling only when dragging horizontally
        event.preventDefault();
        if (this.isDraggingHorizontally) {
          // Block only horizontal page swipes
          event.stopPropagation();
        }
        this.move(currentX, currentY);
      }
    }, { passive: false });

    this.renderer.listen(this.el.nativeElement, 'touchend', (event: TouchEvent) => {
      this.handleTouchEnd(event);
    });
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.movedDistance = 0;
    this.justDragged = false;
    this.startDrag(event.clientX, event.clientY);
    event.preventDefault();
    // Notify when the drag star
    this.swipeService.setDraggingState(true);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.move(event.clientX, event.clientY);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.handleMouseUp(event);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.justDragged) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.justDragged = false;
    }
  }

  private startDrag(clientX: number, clientY: number) {
    this.dragging = true;
    this.offsetX = clientX - this.el.nativeElement.getBoundingClientRect().left;
    this.offsetY = clientY - this.el.nativeElement.getBoundingClientRect().top;
  }

  private move(clientX: number, clientY: number) {
    if (!this.dragging) return;

    const newX = clientX - this.offsetX;
    const newY = clientY - this.offsetY;

    const dx = Math.abs(newX - this.el.nativeElement.getBoundingClientRect().left);
    const dy = Math.abs(newY - this.el.nativeElement.getBoundingClientRect().top);
    this.movedDistance += dx + dy;

    if (this.movedDistance > this.threshold) {
      this.justDragged = true;
    }

    this.el.nativeElement.style.left = `${newX}px`;
    this.el.nativeElement.style.top = `${newY}px`;

    this.savePosition(newX, newY);
  }

  private handleMouseUp(event: MouseEvent) {
    this.stopDrag();
    
    // Notify when the drag is complete
    this.swipeService.setDraggingState(false);

    if (this.justDragged) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  private handleTouchEnd(event: TouchEvent) {
    this.stopDrag();
    this.swipeService.setDraggingState(false);

    if (!this.justDragged) {
      event.target?.dispatchEvent(new Event('click', { bubbles: true }));
    }
  }

  private stopDrag() {
    this.dragging = false;
  }

  private savePosition(x: number, y: number) {
    const key = this.isLarge ? 'largeButtonPosition' : 'smallButtonPosition';
    localStorage.setItem(key, JSON.stringify({ x, y }));
  }

  private loadPosition() {
    const key = this.isLarge ? 'largeButtonPosition' : 'smallButtonPosition';
    const savedPosition = localStorage.getItem(key);
  
    if (savedPosition) {
      const { x, y } = JSON.parse(savedPosition);
      this.el.nativeElement.style.position = 'fixed';
      this.el.nativeElement.style.left = `${x}px`;
      this.el.nativeElement.style.top = `${y}px`;
    } else {
      this.setDefaultPosition(); 
    }
  }
  
private setDefaultPosition() {
    this.el.nativeElement.style.position = 'fixed';
    if (this.isLarge) {
      const savedSmallPosition = localStorage.getItem('smallButtonPosition');
      if (savedSmallPosition) {
        const { x, y } = JSON.parse(savedSmallPosition);
        this.el.nativeElement.style.left = `${x}px`;
        this.el.nativeElement.style.top = `${y}px`;
      } else {
        this.el.nativeElement.style.left = `6px`;
        this.el.nativeElement.style.bottom = `6px`;
      }
    } else {
      this.el.nativeElement.style.left = `6px`;
      this.el.nativeElement.style.bottom = `6px`;
      this.el.nativeElement.style.top = 'auto'; 
      this.savePosition(6, window.innerHeight - 60)
    }
  }
  
  private handleSizeChange() {
    if (this.isLarge) {
      this.loadPosition(); 
    } else {
      this.setDefaultPosition(); 
    }
  }
}