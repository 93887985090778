import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SwipeService {

    public onSwipeLeft: Subject<void> = new Subject();
    public onSwipeRight: Subject<void> = new Subject();
    
    private isDesktop: boolean;
    private isDraggingButton = false;

    constructor() {

        this.isDesktop = !('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }

    public swipeLeft() {
        
        if (!this.isDesktop && !this.isDraggingButton) {
            this.onSwipeLeft.next();
        }
    }

    public swipeRight() {

        if (!this.isDesktop && !this.isDraggingButton) {
            this.onSwipeRight.next();
        }
    }

    public setDraggingState(isDragging: boolean) {

        this.isDraggingButton = isDragging;
    }
}